import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/InstallingSparkLayout.js";
import styleCheck from '../../images/installing-spark/angular/style-check.png';
import spinnergif from '../../images/installing-spark/html/html-spinner.gif';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Installing Spark in an Angular Project`}</h1>
    <p>{`This guide will walk you through
installing Spark on an
existing Angular application.`}</p>
    <p>{`By the end of this guide, you'll have
Spark JavaScript and CSS available
in your application to start
building components.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This guide covers setting up Spark for
Angular 7, 8 and 9. Spark doesn’t
currently support Angular JS.`}</p>
    </blockquote>
    <p>{`Before you begin, make sure that:`}</p>
    <ul>
      <li parentName="ul">{`You have a functioning Angular app similar
to the one in the `}<a parentName="li" {...{
          "href": "https://angular.io/cli"
        }}>{`Angular CLI Overview`}</a></li>
      <li parentName="ul"><a href="https://sass-lang.com/" target="_blank">Sass</a> is installed and functioning</li>
      <li parentName="ul"><a href="https://angular.io/guide/router" target="_blank">Angular Routing</a> is installed
(not required by Angular CLI, but it is required for Spark)</li>
    </ul>
    <p>{`For help with setting up the
necessary development environment, see the
`}<a parentName="p" {...{
        "href": "https://angular.io/cli"
      }}>{`Angular CLI Overview`}</a>{`.`}</p>
    <p>{`Our `}<a href="https://github.com/sparkdesignsystem/spark-starter-angular/" target="_blank">{`starter app examples`}</a>{` are also available for reference:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-angular/tree/kitchen-sink"
        }}>{`'kitchen-sink' branch`}</a>{` (Spark Installed with component examples.)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-angular/tree/without-spark"
        }}>{`'without-spark' branch`}</a>{` (All of the prerequisites before Spark Installation.)`}</li>
    </ul>
    <h2>{`Installing Spark Packages`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Spark Angular also supports schematics. If you'd like to install Spark in this way, install Angular CDK if you haven't already (`}<inlineCode parentName="p">{`npm install -g @angular/cdk`}</inlineCode>{`), and then run `}<inlineCode parentName="p">{`ng add @sparkdesignsystem/spark-angular`}</inlineCode>{`.`}</p>
    </blockquote>
    <ol>
      <li parentName="ol">{`Start by going to your project
directory and installing `}<inlineCode parentName="li">{`spark-angular`}</inlineCode>{`.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @sparkdesignsystem/spark-angular --save-dev
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`In your `}<inlineCode parentName="li">{`app.module.ts`}</inlineCode>{` file, do the following:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Import the `}<inlineCode parentName="li">{`spark-angular`}</inlineCode>{` library`}</li>
      <li parentName="ul">{`Import the `}<inlineCode parentName="li">{`BrowserAnimationsModule`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Include both in `}<inlineCode parentName="li">{`@NgModule`}</inlineCode>{`'s `}<inlineCode parentName="li">{`imports`}</inlineCode>{` array.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SparkAngularModule } from '@sparkdesignsystem/spark-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent
  ],

  imports: [
    SparkAngularModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule
  ],

  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`In your `}<inlineCode parentName="li">{`styles.scss`}</inlineCode>{` file, `}<inlineCode parentName="li">{`@import`}</inlineCode>{` the Spark
stylesheet.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import "node_modules/@sparkdesignsystem/spark-styles/spark.scss"
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`We recommend importing Spark Styles globally, because importing at the component level can lead to unexpected behavior.`}</p>
    </blockquote>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">
        <p parentName="li">{`In the project's `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` file, add the `}<inlineCode parentName="p">{`sprk-u-JavaScript`}</inlineCode>{` CSS class to the root `}<inlineCode parentName="p">{`<html>`}</inlineCode>{` element
in your application.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Build and run the application with
`}<inlineCode parentName="p">{`npm run start`}</inlineCode>{`. When finished, the app will run on `}<inlineCode parentName="p">{`http://localhost:4200/`}</inlineCode>{`
in your web browser.`}</p>
      </li>
    </ol>
    <p>{`You can verify that Spark’s
styles are being included by
opening your browser’s developer
tools and inspecting the DOM.
There should be a `}<inlineCode parentName="p">{`<style>`}</inlineCode>{`
tag in the `}<inlineCode parentName="p">{`<head>`}</inlineCode>{` that includes
Spark’s styles.`}</p>
    <div className="sprk-u-Measure">
  <img className="sprk-u-mbm" src={styleCheck} alt="Screenshot of DOM with Spark Styles" />
    </div>
    <h2>{`Adding Your First Spark Component`}</h2>
    <p>{`Adding a Spinner Button can confirm you've installed Spark Angular packages correctly.`}</p>
    <p>{`This is just one example to implement functionality and get your project started.`}</p>
    <ol>
      <li parentName="ol">{`In your `}<inlineCode parentName="li">{`app.component.ts`}</inlineCode>{` create a click handler and a variable that track state.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isAppLoading: boolean = false;

  @HostListener('click')
  onClick() {
    this.isAppLoading = true;
  }
}
`}</code></pre>
    <p>{`This code sample:`}</p>
    <ul>
      <li parentName="ul">{`Imports `}<inlineCode parentName="li">{`Component`}</inlineCode>{` and `}<inlineCode parentName="li">{`HostListener`}</inlineCode>{` from `}<inlineCode parentName="li">{`@angular/core`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Creates `}<inlineCode parentName="li">{`isAppLoading`}</inlineCode>{`, a variable we'll use to track state.`}</li>
      <li parentName="ul">{`Adds a `}<inlineCode parentName="li">{`HostListener`}</inlineCode>{` that will set `}<inlineCode parentName="li">{`isAppLoading`}</inlineCode>{` to `}<inlineCode parentName="li">{`true`}</inlineCode>{` on click.`}</li>
    </ul>
    <ol>
      <li parentName="ol">{`In your `}<inlineCode parentName="li">{`app.component.html`}</inlineCode>{` file, add a `}<inlineCode parentName="li">{`<button>`}</inlineCode>{` element with a `}<inlineCode parentName="li">{`sprkButton`}</inlineCode>{` directive.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`<button
  sprkButton
  [isSpinning]="isAppLoading"
>
  <div
    sprkSpinner
    *ngIf="isAppLoading"
  >
  </div>
  <div *ngIf="!isAppLoading">
    Start Spinning
  </div>
</button>
`}</code></pre>
    <p>{`This `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` has:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`sprkButton`}</inlineCode>{` directive.`}</li>
      <li parentName="ul">{`An `}<inlineCode parentName="li">{`isSpinning`}</inlineCode>{` input that is determined by the variable `}<inlineCode parentName="li">{`isAppLoading`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Button content that will toggle between the Spark Spinner or Button text. This is determined by the variable `}<inlineCode parentName="li">{`isAppLoading`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`Rebuild with `}<inlineCode parentName="p">{`npm run start`}</inlineCode>{` and you should find a Spark Button loads a spinner on click!`}</p>
    <div className="sprk-u-Measure">
  <img className="sprk-u-mbm" style={{
        "margin": "auto",
        "display": "block"
      }} src={spinnergif} alt="A Spark Button Spinner component." />
    </div>
    <blockquote>
      <p parentName="blockquote">{`Learn more about Spark Buttons and Spinner functionality in the `}<a parentName="p" {...{
          "href": "https://angular.sparkdesignsystem.com/?path=/docs/components-button--primary"
        }}>{`Button Storybook documentation`}</a>{`.`}</p>
    </blockquote>
    <h2>{`Finding the code to other Spark Components`}</h2>
    <ol>
      <li parentName="ol">{`Go to the `}<a parentName="li" {...{
          "href": "https://angular.sparkdesignsystem.com/"
        }}>{`Spark Angular Storybook`}</a>{`.`}</li>
      <li parentName="ol">{`Find the Component you need in the "Components" section.`}</li>
      <li parentName="ol">{`Navigate to the `}<em parentName="li">{`Docs`}</em>{` tab. It's typically at the top of screen next to `}<em parentName="li">{`Canvas`}</em>{`.`}</li>
      <li parentName="ol">{`Navigate down the page until you find your component variant.`}</li>
      <li parentName="ol">{`The `}<em parentName="li">{`Show code`}</em>{` button will toggle a code sample.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`These code samples represent the final `}<em parentName="p">{`rendered state`}</em>{` of the components. Just like with a Spark Button with a Spinner, some functionality requires additional engineering.`}</p>
    </blockquote>
    <p>{`The `}<em parentName="p">{`Docs`}</em>{` section of each page will give implementation details for each component.`}</p>
    <h2>{`Additional Topics`}</h2>
    <p>{`Check out these guides for more information on setting up Spark:`}</p>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/installing-spark/installing-icons"
        }}>{`Icon Installation Guide`}</a>{`
for importing the Spark SVG Icon Set`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/installing-spark/installing-fonts"
        }}>{`Font Installation Guide`}</a>{` for instructions
on using the Rocket Sans font`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      